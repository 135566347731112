<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <nerve-dialog
    v-model="dialogState"
    persistent
    scrollable
    max-height="300"
    max-width="530"
    @keydown.esc="closeDialog()"
    @keydown.enter="merge()"
  >
    <v-card class="fix-case-dialog">
      <v-card-title>
        {{ $t('labels.merge.title') }}
      </v-card-title>
      <v-card-text>
        <v-combobox
          id="iiotFixCaseDialogCombobox"
          ref="mergeKeyValue"
          v-model="key"
          :items="keys"
          :label="$t('labels.merge.fixCaseLabel')"
          :search-input.sync="searchKey"
          :rules="[rules.required, rules.labelKeyPattern, rules.maxLength]"
          autofocus
          @keydown="getKeys"
          @focus="fetchLabels({ value: '' })"
          @update:search-input="setKey"
        />
      </v-card-text>
      <v-card-actions>
        <nerve-button
          id="iiotFixCaseDialogCancelNewKeyName"
          :text="$t('labels.merge.cancelBtn')"
          type-of-btn="cancel"
          size="normal"
          class="ma-0 pa-0 mr-3"
          ref="closeBtn"
          @click-event="closeDialog()"
        />
        <nerve-button
          id="iiotFixCaseDialogUpdateNewKeyName"
          :text="$t('labels.merge.saveBtn')"
          :disabled="!isFormValid"
          type-of-btn="action"
          size="normal"
          class="ma-0 pa-0"
          ref="mergeBtn"
          @click-event="merge()"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>

<script>

import { debounce } from 'lodash';
import { NerveButton, NerveDialog } from 'nerve-ui-components';
import { KEY_VALUE_LABEL_LENGTH, VALIDATION_REGEX } from '@/constants';
import Logger from '@/utils/logger';

export default {
  name: 'FixCaseDialog',
  components: { NerveButton, NerveDialog },
  data() {
    return {
      key: '',
      searchKey: '',
      isFormValid: false,
    };
  },
  computed: {
    dialogState() {
      return this.$store.getters['labels/showFixCaseDialog'];
    },
    rules() {
      return {
        required: (value) => !!value || this.$t('labels.details.required'),
        labelKeyPattern: (value) => VALIDATION_REGEX.LETTER_NUMBER_DASH_AND_UNDERSCORE.test(value) || this.$t('labels.details.labelKeyPattern'),
        maxLength: (value) => (value && value.length <= KEY_VALUE_LABEL_LENGTH) || this.$t('labels.details.maxLength', { fieldLength: KEY_VALUE_LABEL_LENGTH }),
      };
    },

    keys() {
      return this.$store.getters['labels/keys'];
    },

    group() {
      return this.$store.getters['labels/selectedGroupLabel'];
    },
  },

  methods: {
    async fetchLabels(filterBy) {
      try {
        await this.$store.dispatch('labels/fetchForAutocomplete', {
          limit: 10,
          page: 1,
          filterBy,
        });
      } catch (e) {
        Logger.error(e);
      }
    },

    // eslint-disable-next-line func-names
    getKeys: debounce(async function () {
      await this.fetchLabels({ key: this.searchKey || '' });
    }, 500),

    setKey(e) {
      if (e) {
        this.key = e.toString();
      }
    },

    closeDialog() {
      this.$store.dispatch('labels/show_fix_case_dialog', false);
    },

    async merge() {
      try {
        const payload = {
          keys: this.group.map((group) => group.id),
          newKeyName: this.key,
        };
        const params = this.$route.query;
        await this.$store.dispatch('labels/merge_labels', payload);
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'labels.merge.successfullyMerged',
          color: 'success',
          showClose: true,
        });
        const labelsNumber = this.$store.getters['labels/groupLabels'].length;
        if (labelsNumber === 1) {
          params.page = 1;
        }
        await this.$store.dispatch('labels/group_labels', params);
        this.$root.$refs.LabelMerge.changedParams(params);
        this.$router.push({ path: this.$route.fullPath, query: { page: params.page } });
        await this.$store.dispatch('labels/show_fix_case_dialog', false);
        await this.resetSelectedGroups();
        this.$router.push({ name: 'Labels', query: this.$store.getters['labels/getQuery'] });
      } catch (e) {
        Logger.error(e);
      }
    },

    resetSelectedGroups() {
      this.$store.dispatch('labels/reset_selected_group_label');
    },
  },
  updated() {
    this.isFormValid = this.$refs.mergeKeyValue?.validate();
  },

};
</script>
<style lang="scss" scoped>
  .fix-case-dialog {
    max-height: 460px !important;
    min-height: 320px !important;
  }
</style>
