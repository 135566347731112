<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div>
    <the-nerve-table
      :id="'iiotLabelMergeTable'"
      :columns="columns"
      :params="params"
      :empty-state="$t('labels.merge.emptyTable')"
      :is-action-menu-enabled="false"
      :is-check-box-column-without-select-all-enabled="true"
      custom-fetch-action="group_labels"
      store-module="labels"
      selected-items-getter="selectedGroupLabel"
      custom-list-getter="groupLabels"
      custom-count-getter="countGroupedLabels"
      check-item-action="select_label_group"
      :class="{'wrapper-class' : items.length >= 1}"
      class="mr-8"
      @row-clicked="params => this.$emit('row-selected', params.item)"
      @params-changed="params => changedParams(params)"
    />
    <v-col>
      <v-row>
        <v-col cols="12">
          <div>
            <nerve-button
              id="iiotLabelMergeCancel"
              :text="$t('labels.merge.cancelBtn')"
              type-of-btn="cancel"
              size="normal"
              ref="iiotLabelMergeCancel"
              class="mr-5 ml-0"
              @click-event="cancel()"
            />
            <nerve-button
              v-if="canAccess('UI_LABEL:MERGE')"
              id="iiotLabelMergeButton"
              :disabled="isMergeDisabled"
              :text="$t('labels.merge.mergeBtn')"
              type-of-btn="action"
              size="normal"
              ref="iiotLabelMergeButton"
              @click-event="mergeLabelKeys()"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { TheNerveTable, NerveButton } from 'nerve-ui-components';

export default {
  components: {
    TheNerveTable,
    NerveButton,
  },
  props: {
    page: {
      type: Number,
    },
    itemsPerPage: {
      type: Number,
    },
    search: {
      type: String,
    },
  },
  data() {
    return {
      params: {},
    };
  },
  computed: {
    isMergeDisabled() {
      return this.$store.getters['labels/selectedGroupLabel'].length < 2;
    },
    columns() {
      return [
        {
          text: this.$t('labels.merge.keysHeader'),
          value: 'key',
          sortable: false,
        },
        {
          text: this.$t('labels.merge.caseHeader'),
          value: 'case',
          sortable: false,
          component: {
            sfc: () => import('@/components/labels/merge/FixCase.vue'),
          },
        },
        {
          text: this.$t('labels.merge.numOfOccurrences'),
          value: 'count',
          sortable: false,
        },
        {
          text: '',
          value: 'fixCaseBtn',
          sortable: false,
          component: {
            sfc: () => import('@/components/labels/merge/FixCaseButton.vue'),
          },
        },
      ];
    },
    items() {
      return this.$store.getters['labels/groupLabels'];
    },
  },
  destroyed() {
    this.$store.dispatch('labels/reset_selected_group_label');
  },
  created() {
    this.$root.$refs.LabelMerge = this;
    this.params = {
      page: this.page || 1,
      itemsPerPage: this.itemsPerPage || 10,
      search: this.search || '',
    };
  },
  methods: {
    changedParams(params) {
      this.params = params;
      this.$router.push({
        name: 'Merge',
        query: {
          page: params.page,
          itemsPerPage: params.itemsPerPage,
          search: params.search || undefined,
        },
      }).catch(() => {});
    },
    mergeLabelKeys() {
      this.$store.dispatch('labels/show_fix_case_dialog', true);
    },

    cancel() {
      this.$router.push({ name: 'Labels', query: this.$store.getters['labels/getQuery'] });
    },
  },
};
</script>
<style lang="scss">
  .wrapper-class {
    .v-data-table__wrapper {
      padding-left: 40px;
    }
  }
</style>
